// import { useState } from "react";
import {
  Link,
  // useNavigate,
} from "react-router-dom";
import "bootstrap-icons/font/bootstrap-icons.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";

export function StatNavbar(props) {
  const matchCategories = props.matchCategories;
  const matchStageNumber = props.matchStageNumber;
  const stageMenu = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24,
  ];
  // const navigate = useNavigate();
  // const [navExpanded, setNavExpanded] = useState(false);
  // console.log("nav cate: ", matchCategories);

  function GenNavDropdown() {
    let NavDDItem = "";

    for (let i = 1; i <= 10; i++) {
      NavDDItem += (
        <NavDropdown.Item
          eventKey={"stage"}
          as={Link}
          to={"/result/stage/" + i}
        >
          Stage # 1 Div
        </NavDropdown.Item>
      );
    }

    return NavDDItem;
  }

  return (
    <Navbar
      collapseOnSelect
      bg="primary"
      expand="sm"
      data-bs-theme="dark"
      className="bg-body-primary"
    // expanded={navExpanded}
    >
      <Container>
        <Navbar.Brand href="/">SOTH</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <NavDropdown
              title="Overall"
              id="collapsible-nav-dropdown"
              data-bs-theme="light"
            >
              <NavDropdown.Item
                eventKey={"overall"}
                as={Link}
                to={"/result/overall"}
              >
                <i className="bi bi-check text-info me-2"></i>All Div
              </NavDropdown.Item>
              <NavDropdown.Item
                eventKey={"overall/pistol"}
                as={Link}
                to={"/result/overall/pistol"}
              >
                <i className="bi bi-check text-info me-2"></i>Pistol Div
              </NavDropdown.Item>
              <NavDropdown.Item
                eventKey={"overall/pcc"}
                as={Link}
                to={"/result/overall/pcc"}
              >
                <i className="bi bi-check text-info me-2"></i>PCC Div
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                eventKey={"overall/semi"}
                as={Link}
                to={"/result/overall/semi"}
              >
                Semi-auto Div
              </NavDropdown.Item>
              <NavDropdown.Item
                eventKey={"overall/rev"}
                as={Link}
                to={"/result/overall/rev"}
              >
                REV Div
              </NavDropdown.Item>
              <NavDropdown.Item
                eventKey={"overall/bug"}
                as={Link}
                to={"/result/overall/bug"}
              >
                BUG Div
              </NavDropdown.Item>
              <NavDropdown.Item
                eventKey={"overall/co"}
                as={Link}
                to={"/result/overall/co"}
              >
                CO Div
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown
              title="Most"
              id="collapsible-nav-dropdown"
              data-bs-theme="light"
            >
              <NavDropdown.Item eventKey={"most"} as={Link} to={"/result/most"}>
                <i className="bi bi-check text-info me-2"></i>All Div
              </NavDropdown.Item>
              <NavDropdown.Item
                eventKey={"most/pistol"}
                as={Link}
                to={"/result/most/pistol"}
              >
                <i className="bi bi-check text-info me-2"></i>Pistol Div
              </NavDropdown.Item>
              <NavDropdown.Item
                eventKey={"most/pcc"}
                as={Link}
                to={"/result/most/pcc"}
              >
                <i className="bi bi-check text-info me-2"></i>PCC Div
              </NavDropdown.Item>
              {/* <NavDropdown.Divider />
              <NavDropdown.Item
                eventKey={"most/semi"}
                as={Link}
                to={"/result/most/semi"}
              >
                Semi-auto Div
              </NavDropdown.Item>
              <NavDropdown.Item
                eventKey={"most/rev"}
                as={Link}
                to={"/result/most/rev"}
              >
                REV Div
              </NavDropdown.Item>
              <NavDropdown.Item
                eventKey={"most/bug"}
                as={Link}
                to={"/result/most/bug"}
              >
                BUG Div
              </NavDropdown.Item>
              <NavDropdown.Item
                eventKey={"most/co"}
                as={Link}
                to={"/result/most/co"}
              >
                CO Div
              </NavDropdown.Item> */}
            </NavDropdown>
            <Nav.Link eventKey={"dc"} as={Link} to={"/result/dc"}>
              D.C.
            </Nav.Link>
            <NavDropdown
              title="DC"
              id="collapsible-nav-dropdown"
              data-bs-theme="light"
              className="d-none"
            >
              <NavDropdown.Item eventKey={"dc"} as={Link} to={"/result/dc"}>
                All Div
              </NavDropdown.Item>
              {/* <NavDropdown.Divider /> */}
              {/* <NavDropdown.Item
                                eventKey={"dc/ssp"}
                                as={Link}
                                to={"/result/dc/ssp"}
                            >
                                SSP Div
                            </NavDropdown.Item>
                            <NavDropdown.Item
                                eventKey={"dc/esp"}
                                as={Link}
                                to={"/result/dc/esp"}
                            >
                                ESP Div
                            </NavDropdown.Item>
                            <NavDropdown.Item
                                eventKey={"dc/ccp"}
                                as={Link}
                                to={"/result/dc/ccp"}
                            >
                                CCP Div
                            </NavDropdown.Item>
                            <NavDropdown.Item
                                eventKey={"dc/cdp"}
                                as={Link}
                                to={"/result/dc/cdp"}
                            >
                                CDP Div
                            </NavDropdown.Item>
                            <NavDropdown.Item
                                eventKey={"dc/bug"}
                                as={Link}
                                to={"/result/dc/bug"}
                            >
                                BUG Div
                            </NavDropdown.Item>
                            <NavDropdown.Item
                                eventKey={"dc/rev"}
                                as={Link}
                                to={"/result/dc/rev"}
                            >
                                REV Div
                            </NavDropdown.Item>
                            <NavDropdown.Item
                                eventKey={"dc/co"}
                                as={Link}
                                to={"/result/dc/co"}
                            >
                                CO Div
                            </NavDropdown.Item>
                            <NavDropdown.Item
                                eventKey={"dc/pcc"}
                                as={Link}
                                to={"/result/dc/pcc"}
                            >
                                PCC Div
                            </NavDropdown.Item>
                            <NavDropdown.Item
                                eventKey={"dc/spd"}
                                as={Link}
                                to={"/result/dc/spd"}
                            >
                                SPD Div
                            </NavDropdown.Item> */}
            </NavDropdown>
            <NavDropdown
              title="Div/Class"
              id="collapsible-nav-dropdown"
              data-bs-theme="light"
            >
              <NavDropdown.Item
                eventKey={"class"}
                as={Link}
                to={"/result/class"}
              >
                <i className="bi bi-check text-info me-2"></i>All Div
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                eventKey={"class/ssp"}
                as={Link}
                to={"/result/class/ssp"}
              >
                <i className="bi bi-check text-info me-2"></i>SSP Div
              </NavDropdown.Item>
              <NavDropdown.Item
                eventKey={"class/esp"}
                as={Link}
                to={"/result/class/esp"}
              >
                <i className="bi bi-check text-info me-2"></i>ESP Div
              </NavDropdown.Item>
              <NavDropdown.Item
                eventKey={"class/ccp"}
                as={Link}
                to={"/result/class/ccp"}
              >
                <i className="bi bi-check text-info me-2"></i>CCP Div
              </NavDropdown.Item>
              <NavDropdown.Item
                eventKey={"class/cdp"}
                as={Link}
                to={"/result/class/cdp"}
              >
                <i className="bi bi-check text-info me-2"></i>CDP Div
              </NavDropdown.Item>
              <NavDropdown.Item
                eventKey={"class/bug"}
                as={Link}
                to={"/result/class/bug"}
              >
                <i className="bi bi-check text-info me-2"></i>BUG Div
              </NavDropdown.Item>
              <NavDropdown.Item
                eventKey={"class/rev"}
                as={Link}
                to={"/result/class/rev"}
              >
                <i className="bi bi-check text-info me-2"></i>REV Div
              </NavDropdown.Item>
              <NavDropdown.Item
                eventKey={"class/co"}
                as={Link}
                to={"/result/class/co"}
              >
                <i className="bi bi-check text-info me-2"></i>CO Div
              </NavDropdown.Item>
              <NavDropdown.Item
                eventKey={"class/pcc"}
                as={Link}
                to={"/result/class/pcc"}
              >
                <i className="bi bi-check text-info me-2"></i>PCC Div
              </NavDropdown.Item>
              <NavDropdown.Item
                eventKey={"class/spd"}
                as={Link}
                to={"/result/class/spd"}
              >
                <i className="bi bi-check text-info me-2"></i>SPD Div
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown
              title="Categories"
              id="collapsible-nav-dropdown"
              data-bs-theme="light"
            >
              <NavDropdown.Item eventKey={"cate"} as={Link} to={"/result/cate"}>
                <i className="bi bi-check text-info me-2"></i>All Categories
              </NavDropdown.Item>
              {matchCategories.lenght ? <NavDropdown.Divider /> : null}
              {matchCategories.lenght ? matchCategories.map((item, key) => (
                <NavDropdown.Item
                  key={key}
                  eventKey={`cate/${item.category}`}
                  as={Link}
                  to={`/result/cate/${item.category}`}
                >
                  <i className="bi bi-check text-info me-2"></i>
                  {item.category.toUpperCase()}
                </NavDropdown.Item>
              )) : null}
            </NavDropdown>
            <NavDropdown
              title="Stages"
              id="collapsible-nav-dropdown"
              data-bs-theme="light"
            // className="d-none"
            >
              {stageMenu.slice(0, matchStageNumber).map((number) => (
                <NavDropdown.Item
                  key={"stage" + number}
                  eventKey={"stage" + number}
                  as={Link}
                  to={"/result/stage/" + number}
                >
                  <i className="bi bi-check text-info me-2"></i>Stage # {number} Div
                </NavDropdown.Item>
              ))}
            </NavDropdown>
            <Nav.Link eventKey={"dnf"} as={Link} to={"/result/dnf"}>
              DNF
            </Nav.Link>
            <Nav.Link
              className="d-none"
              eventKey={"dq"}
              as={Link}
              to={"/result/dq"}
            >
              DQ
            </Nav.Link>
            <Nav.Link href="#signout">Logout</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
