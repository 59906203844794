// import { useNavigate } from "react-router-dom";

export default function Signout() {
    // const navigate = useNavigate();
    
        sessionStorage.clear();
        // navigate("/");
        // window.location.replace("/sign/p=signout");
        window.location.replace("/");

    // window.location.replace("http://localhost:3001/");
}
