import Table from "react-bootstrap/Table";

const classes = ["DM", "MA", "EX", "SS", "MM", "NV", "UN"];
const divisions = [
  "SSP",
  "ESP",
  "CCP",
  "CDP",
  "BUG",
  "REV",
  "CO",
  "PCC",
  "SPD",
];

export function StatOverAll(props) {
  const title = props.title;
  const items = props.items;
  return (
    <>
      <h5>{title}</h5>
      <Table
        responsive="lg"
        striped
        bordered
        hover
        style={{ fontSize: "0.75em", width: "100%" }}
        className="TblResult"
      >
        <thead>
          <tr>
            <th className="text-center text-nowrap">Place</th>
            <th className="text-start text-nowrap">Shooter</th>
            <th className="text-center text-nowrap">Div / Class</th>
            <th className="text-center text-nowrap">No / SQ</th>
            {/* <th className="text-center">Categories</th> */}
            <th className="text-center text-nowrap">Time</th>
            <th className="text-center text-nowrap">PD</th>
            <th className="text-center text-nowrap">HNT</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item, key) => (
            <tr key={key}>
              <td className="text-center">{key + 1}</td>
              <td className="text-nowrap">
                {item["shooter_idpa"] ? (
                  <div className="idpaNumber">{item["shooter_idpa"]}</div>
                ) : null}
                {`${item["shooter_firstName"]}  ${item["shooter_lastName"]}`}
              </td>
              <td className="text-center text-nowrap align-content-center">
                {item["shooter_division"] + " / " + item["shooter_class"]}
              </td>
              <td className="text-center text-nowrap align-content-center">
                {item["shooter_number"] + " / " + item["shooter_squad"]}
              </td>
              {/* <td className="text-center">{item["shooter_categories"]}</td> */}
              <td className="text-center align-content-center">{item["shooter_total_score"]}</td>
              <td className="text-center align-content-center">{item["shooter_PD"]}</td>
              <td className="text-center align-content-center">{item["shooter_HNT"]}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}

export function DivisionChampionAll(props) {
  const items = props.items;
  // if (!Object.keys(items).length) {
  //     window.location.replace("/");
  // }
  
  if (items.length) {
    // console.log("AOR");
    console.log("items dc: ", items);
  }
  return (
    <>
      <h5 className="text-center text-danger">{`Division Champion : ${"div"} (${
        Object.keys(items).length
      })`}</h5>
      {divisions.map((div, key) => {
        // return genDCDivision(items[div], div);
        return (
          <div key={key}>
            {/* Division Champion: {div} */}
            {/* {items[div] ? genDCDivision(items[div], div) : "NO DIV"} */}
            {/* {genTblDivChampion(div, items[div].slice(0, 3), false)} */}
            {items[div]
              ? genTblDivChampion(div, items[div].slice(0, 3), false)
              : null}
            {/* {genDCDivision(items[div], div)} */}
          </div>
        );
      })}
    </>
  );
}

export function DivisionChampion(props) {
  const matchShooterCount = props.matchShooterCount;
  const items = props.items;
  const div = props.div;
  let shooterCount = 0;
  // console.log("DC: ", items);
  try {
    classes.map((cls) => {
      shooterCount += Object.keys(items[cls]).length;
    });
  } catch (erro) {}

  function genDivisionChampions() {
    return classes.map((cls, key) => {
      try {
        return Object.keys(items[cls]).length
          ? genTblDivChampion(div, items[cls], cls)
          : null;
      } catch (error) {
        // console.log("DC DM: Failed");
      }
    });
  }

  function genDivisionChampionSPD() {
    try {
      return Object.keys(items["ALL"]).length
        ? genTblDivChampion(div, items["ALL"], "ALL")
        : null;
    } catch (error) {
      // console.log("DC DM: Failed");
    }
  }

  return (
    <>
      <h5 id="pageTitle" className="text-center">
        {`Division Champions : ${div.toUpperCase()} (${shooterCount}/${matchShooterCount})`}
      </h5>
      {div === "SPD" ? genDivisionChampionSPD() : genDivisionChampions()}
    </>
  );
}

export function DivisionClassAll(props) {
  const items = props.items;
  const matchShooterCount = props.matchShooterCount;
  // if (!Object.keys(items).length) {
  //     window.location.replace("/");
  // }
  // console.log("AOR");
  // console.log("items dc: ", items);
  return (
    <>
      <h5 className="text-center text-danger">{`Divison Class (${
        Object.keys(items).length
      }/${matchShooterCount})`}</h5>
      {divisions.map((div, key) => {
        // return genDCDivision(items[div], div);
        return div !== "SPD"
          ? classes.map((cls, ckey) => {
              const itemDivCls = items.filter((item) => {
                return (
                  item.shooter_division === div && item.shooter_class === cls
                );
              });
              console.log("itemDivCls dc: ", itemDivCls);
              return itemDivCls.length
                ? genTblDivChampion(div, itemDivCls, cls)
                : null;
            })
          : items.filter((item) => item.shooter_division === div).length
          ? genTblDivChampion(
              div,
              items.filter((item) => item.shooter_division === div),
              "ALL"
            )
          : null;
      })}
    </>
  );
}

export function DivisionClass(props) {
  const matchShooterCount = props.matchShooterCount;
  const items = props.items;
  const div = props.div;
  if (items.length && div) {
    // console.log("D/C: ", div, items);
  }

  function genDivisionClass() {
    return classes.map((cls, key) => {
      return Object.keys(items.filter((item) => item.shooter_class === cls))
        .length
        ? genTblDivChampion(
            div,
            items.filter((item) => item.shooter_class === cls),
            cls
          )
        : null;
    });
  }

  function genDivisionClassSPD() {
    console.log("items:", items);
    return Object.keys(items).length
      ? genTblDivChampion(div, items, "ALL")
      : null;
  }

  return (
    <>
      <h5 id="pageTitle" className="text-center">
        {`Division Class : ${div.toUpperCase()} (${
          items.length
        }/${matchShooterCount})`}
      </h5>
      {div === "SPD" ? genDivisionClassSPD() : genDivisionClass()}
    </>
  );
}

export function DivisionCategoryAll(props) {
  const items = props.items;
  const matchShooterCount = props.matchShooterCount;
  const matchCategories = props.matchCategories;

  function filterData(dataset, cate) {
    if (cate && cate !== "") {
      return dataset.filter((item) => {
        return (
          item.shooter_categories.toLowerCase().includes(cate.toLowerCase()) &&
          item.shooter_DNF === "0" &&
          parseInt(item.shooter_total_score) > 0
        );
      });
    }
  }
  console.log("category: ", matchCategories);
  console.log("category Lady: ", filterData(items, "Lady"));
  return (
    <>
      <h5 className="text-center text-danger">{`Categories (${
        Object.keys(items).length
      }/${matchShooterCount})`}</h5>
      {matchCategories.map((cate) => {
        return genTblCategory(
          cate.category,
          filterData(items, cate.category).slice(0, 5)
        );
      })}
    </>
  );
}

export function DivisionCategory(props) {
  const matchShooterCount = props.matchShooterCount;
  const items = props.items;
  const cate = props.cate;
  console.log("DivCate: ", cate, items);

  function genDivisionClass() {
    return classes.map((cls, key) => {
      return Object.keys(items.filter((item) => item.shooter_class === cls))
        .length
        ? genTblDivChampion(
            cate,
            items.filter((item) => item.shooter_class === cls),
            cls
          )
        : null;
    });
  }

  function genDivisionClassSPD() {
    console.log("items:", items);
    return Object.keys(items).length
      ? genTblDivChampion(cate, items, "ALL")
      : null;
  }

  return (
    <>
      <h5 id="pageTitle" className="text-center">
        {`Category : ${cate.toUpperCase()} (${
          items.length
        }/${matchShooterCount})`}
      </h5>
      {genDivisionClassSPD()}
      {/* {cate === "SPD" ? genDivisionClassSPD() : genDivisionClassSPD()} */}
    </>
  );
}

function genTblDivChampion(div, itemsDivChampion, cls = false) {
  cls = cls ? cls : "ALL";
  // console.log("itemsDivChampion " + div + " / " + cls + ":", itemsDivChampion);
  return (
    <>
      <div key={`Tbl_${div}/${cls ? cls : null}`}>
        <h5 style={{ marginTop: "40px" }}>
          Division: {div}
          {cls ? " / " + cls : null}
          {cls ? " (" + Object.keys(itemsDivChampion).length + ")" : null}
        </h5>
        <Table
          responsive="md"
          striped
          bordered
          hover
          style={{
            fontSize: "0.75em",
            width: "100%",
            marginBottom: "20px",
          }}
          className="TblResult"
        >
          <thead>
            <tr>
              <th
                className="text-center text-nowrap px-2"
                style={{ width: "50px" }}
              >
                Place
              </th>
              <th className="text-start px-2">Shooter</th>
              <th
                className="text-center text-nowrap px-2 d-none d-md-table-cell"
                style={{ width: "90px" }}
              >
                Div / Cls
              </th>
              <th
                className="text-center text-nowrap px-2 d-none d-md-table-cell"
                style={{ width: "80px" }}
              >
                No / SQ
              </th>
              {/* <th className="text-center">Categories</th> */}
              <th
                className="text-center text-nowrap px-2"
                style={{ width: "80px" }}
              >
                Time
              </th>
              <th
                className="text-center text-nowrap px-2"
                style={{ width: "60px" }}
              >
                PD
              </th>
              <th
                className="text-center text-nowrap px-2"
                style={{ width: "60px" }}
              >
                HNT
              </th>
            </tr>
          </thead>
          <tbody>
            {itemsDivChampion
              ? itemsDivChampion.map((item, key) => (
                  <tr key={key}>
                    <td className="text-center">{key + 1}</td>
                    <td className="">
                      {item["shooter_idpa"] ? (
                        <div className="idpaNumber">{item["shooter_idpa"]}</div>
                      ) : null}
                      {`${item["shooter_firstName"]}  ${item["shooter_lastName"]}`}
                    </td>
                    <td className="text-center text-nowrap d-none d-md-table-cell align-content-center">
                      {item["shooter_division"] + " / " + item["shooter_class"]}
                    </td>
                    <td className="text-center text-nowrap d-none d-md-table-cell align-content-center">
                      {item["shooter_number"] + " / " + item["shooter_squad"]}
                    </td>
                    {/* <td className="text-center">{item["shooter_categories"]}</td> */}
                    <td className="text-center align-content-center">
                      {item["shooter_total_score"]}
                    </td>
                    <td className="text-center align-content-center">{item["shooter_PD"]}</td>
                    <td className="text-center align-content-center">{item["shooter_HNT"]}</td>
                  </tr>
                ))
              : null}
          </tbody>
        </Table>
      </div>
    </>
  );
}

function genTblCategory(cate, itemCategories) {
  // console.log("itemCategories " + div + " / " + cls + ":", itemCategories);
  return (
    <>
      <div key={`Tbl_${cate}`}>
        <h5 style={{ marginTop: "40px" }}>
          Category: {cate}
          {" (" + Object.keys(itemCategories).length + ")"}
        </h5>
        <Table
          responsive="md"
          striped
          bordered
          hover
          style={{
            fontSize: "0.75em",
            width: "100%",
            marginBottom: "20px",
          }}
          className="TblResult"
        >
          <thead>
            <tr>
              <th
                className="text-center text-nowrap px-2"
                style={{ width: "50px" }}
              >
                Place
              </th>
              <th className="text-start px-2">Shooter</th>
              <th
                className="text-center text-nowrap px-2 d-none d-md-table-cell"
                style={{ width: "90px" }}
              >
                Div / Cls
              </th>
              <th
                className="text-center text-nowrap px-2 d-none d-md-table-cell"
                style={{ width: "80px" }}
              >
                No / SQ
              </th>
              {/* <th className="text-center">Categories</th> */}
              <th
                className="text-center text-nowrap px-2"
                style={{ width: "80px" }}
              >
                Time
              </th>
              <th
                className="text-center text-nowrap px-2"
                style={{ width: "60px" }}
              >
                PD
              </th>
              <th
                className="text-center text-nowrap px-2"
                style={{ width: "60px" }}
              >
                HNT
              </th>
            </tr>
          </thead>
          <tbody>
            {itemCategories.length
              ? itemCategories.map((item, key) => (
                  <tr key={key}>
                    <td className="text-center">{key + 1}</td>
                    <td className="">
                      {item["shooter_idpa"] ? (
                        <div className="idpaNumber">{item["shooter_idpa"]}</div>
                      ) : null}
                      {`${item["shooter_firstName"]}  ${item["shooter_lastName"]}`}
                    </td>
                    <td className="text-center text-nowrap d-none d-md-table-cell align-content-center">
                      {item["shooter_division"] + " / " + item["shooter_class"]}
                    </td>
                    <td className="text-center text-nowrap d-none d-md-table-cell align-content-center">
                      {item["shooter_number"] + " / " + item["shooter_squad"]}
                    </td>
                    {/* <td className="text-center">{item["shooter_categories"]}</td> */}
                    <td className="text-center align-content-center">
                      {item["shooter_total_score"]}
                    </td>
                    <td className="text-center align-content-center">{item["shooter_PD"]}</td>
                    <td className="text-center align-content-center">{item["shooter_HNT"]}</td>
                  </tr>
                ))
              : null}
          </tbody>
        </Table>
      </div>
    </>
  );
}

export function StageLeader(props) {
  // const matchShooterCount = props.matchShooterCount;
  const stage = props.stage;
  const items = props.items;

  function filterData(dataset, div) {
    if (div && div !== "") {
      return dataset.filter((item) => {
        return div === "PCC" ? item.shooter_division.toUpperCase() === ("PCC") : item.shooter_division.toUpperCase() !== ("PCC");
      });
    }
  }

  const itemsPCC = filterData(items, "PCC");
  const itemsPistol = filterData(items, "Pistol");

  return (
    <>
      <h5 className="text-center text-danger">Leader Stage #{stage}</h5>
      {genTblStageLeader("Pistol", stage, itemsPistol)}
      {genTblStageLeader("PCC", stage, itemsPCC)}
    </>
  );
}

function genTblStageLeader(div, stage, items) {
  // console.log("itemsDivChampion " + div + " / " + cls + ":", itemsDivChampion);
  return (
    <>
      <div key={`Tbl_${div}`}>
        <h5 style={{ marginTop: "40px" }}>
          Division: {div}
          {/* {cls ? " / " + cls : null}
          {cls ? " (" + Object.keys(itemsDivChampion).length + ")" : null} */}
        </h5>
        <Table
          responsive="md"
          striped
          bordered
          hover
          style={{
            fontSize: "0.75em",
            width: "100%",
            marginBottom: "20px",
          }}
          className="TblResult"
        >
          <thead>
            <tr>
              <th
                className="text-center text-nowrap px-2"
                style={{ width: "50px" }}
              >
                Place
              </th>
              <th className="text-start px-2">Shooter</th>
              <th
                className="text-center text-nowrap px-2 d-none d-md-table-cell"
                style={{ width: "100px" }}
              >
                {/* {"No / SQ"} */}
                {"Div / Class"}
              </th>
              {/* <th className="text-center">Categories</th> */}
              <th
                className="text-center text-nowrap px-2"
                style={{ width: "80px" }}
              >
                Total
              </th>
              <th
                className="text-center text-nowrap px-2"
                style={{ width: "60px" }}
              >
                PD
              </th>
              <th
                className="text-center text-nowrap px-2"
                style={{ width: "60px" }}
              >
                PE
              </th>
              <th
                className="text-center text-nowrap px-2"
                style={{ width: "60px" }}
              >
                HNT
              </th>
              <th
                className="text-center text-nowrap px-2"
                style={{ width: "80px" }}
              >
                Time
              </th>
            </tr>
          </thead>
          <tbody>
            {items
              ? items.map((item, key) => (
                  <tr key={key}>
                    <td className="text-center">{key + 1}</td>
                    <td className="">
                      {item["shooter_idpa"] ? (
                        <div className="idpaNumber">{item["shooter_idpa"]}</div>
                      ) : null}
                      {`${item["shooter_firstName"]}  ${item["shooter_lastName"]}`}
                    </td>
                    <td className="text-center text-nowrap d-none d-md-table-cell align-content-center">
                      {/* {item["shooter_number"] + " / " + item["shooter_squad"]} */}
                      {item["shooter_division"] + " / " + item["shooter_class"]}
                    </td>
                    {/* <td className="text-center">{item["shooter_categories"]}</td> */}                     
                    <td className="text-center align-content-center">
                      {item["score_stage_time"]}
                    </td>
                    <td className="text-center align-content-center">{item["score_stage_PD"]}</td>
                    <td className="text-center align-content-center">{item["score_stage_PE"]}</td>
                    <td className="text-center align-content-center">{item["score_stage_HNT"]}</td>
                    <td className="text-center align-content-center">
                      {item["score_stage_string_1"]}
                    </td>
                  </tr>
                ))
              : null}
          </tbody>
        </Table>
      </div>
    </>
  );
}
