export function Footer() {
  return (
    <div
      id="page_footer"
      className="footer py-2 bg-danger text-center bg-gradient text-white fw-bold"
      style={{ letterSpacing: "0.2em" }}
    >
      IDPA Match Stat Presented By SOTH
    </div>
  );
}
