import { useEffect, useState } from "react";
import "../assets/css/prizeMedal.css";

export default function PrizeCounter() {
  const [prizeGold, setPrizeGold] = useState(0);
  const [prizeSilver, setPrizeSilver] = useState(0);
  const [prizeBronze, setPrizeBronze] = useState(0);

  const prizeGoldClickHandler = () => {
    setPrizeGold(prizeGold + 1);
  };


  return (
    <>
      <div className="container mt-5">
        <div className="row g-5 text-center">
          <div className="col">
            <h2 className="display-4">{prizeGold}</h2>
          </div>
          <div className="col">
            <h2 className="display-4">{prizeSilver}</h2>
          </div>
          <div className="col">
            <h2 className="display-4">{prizeBronze}</h2>
          </div>
        </div>
      </div>

      <div className="container mt-5 d-flex justify-content-between flex-wrap">
        <div className="quiz-medal">
          <div className="quiz-medal__circle quiz-medal__circle--gold" onClick={prizeGoldClickHandler()}>
            <span>{prizeGold}</span>
          </div>
        </div>
        <div className="quiz-medal">
          <div className="quiz-medal__circle quiz-medal__circle--silver">
            <span>{prizeSilver}</span>
          </div>
        </div>
        <div className="quiz-medal">
          <div className="quiz-medal__circle quiz-medal__circle--bronze">
            <span>{prizeBronze}</span>
          </div>
        </div>

        {/* <div className="quiz-medal">
          <div className="quiz-medal__circle"></div>
          <div className="quiz-medal__ribbon quiz-medal__ribbon--left"></div>
          <div className="quiz-medal__ribbon quiz-medal__ribbon--right"></div>
        </div> */}
      </div>
    </>
  );
}
