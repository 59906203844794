import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import { useCookies } from "react-cookie";
import AOS from "aos";
import axios from "axios";

import { TableResult } from "../components/Filter_element";
import { Footer } from "../components/Home_element";

export default function Filter(props) {
  //   const mid = props.mid;
  const [matchId, setMatchId] = useState('');
  const { div, cls, cate } = useParams();
  const [filterDiv, setFilterDiv] = useState(div);
  const [filterCls, setFilterCls] = useState(cls);
  const [filterCate, setFilterCate] = useState(cate);
  const [dataLoaded, setDataLoaded] = useState(false);
  //   const [overallPCC, setOverallPCC] = useState(false);
  const [matchInfo, setMatchInfo] = useState();
  const [matchStat, setMatchStat] = useState([]);
  const [matchCategory, setMatchCategory] = useState([]);
  const [overall, setOverall] = useState([]);
  const [dataSet, setDataSet] = useState([]);
  // const [overallPistol, setOverallPistol] = useState([]);
  // const [overallPCC, setOverallPCC] = useState([]);
  // const [mostAll, setMostAll] = useState([]);
  // const [mostPistol, setMostPistol] = useState([]);
  // const [mostPCC, setMostPCC] = useState([]);
  // const [dcAll, setDcAll] = useState([]);
  // // const [dcSSP, setDcSSP] = useState([]);
  // const [dnf, setDnf] = useState([]);
  // const [categoryResult, setCategoryResult] = useState([]);

  //   const classes = ["DM", "MA", "EX", "SS", "MM", "NV", "UN"];
  //   const divisions = [
  //     "SSP",
  //     "ESP",
  //     "CCP",
  //     "CDP",
  //     "BUG",
  //     "REV",
  //     "CO",
  //     "PCC",
  //     "SPD",
  //   ];

  // ! - invert, multi key split by comma
  function filterData(dataset, div, cls, cate) {
    let filteredData = dataset.filter((item) => {
      return item.shooter_DNF === "0" && parseInt(item.shooter_total_score) > 0;
    });

    if (div && div !== "all") {
      filteredData = filteredData.filter((item) => {
        return item.shooter_division === div.toUpperCase();
      });
      console.log("filteredData: ", div);
    }
    if (cls && cls !== "all") {
      filteredData = filteredData.filter((item) => {
        return item.shooter_class === cls.toUpperCase();
      });
      console.log("filteredData: ", cls);
    }
    if (cate && cate !== "") {
      filteredData = filteredData.filter((item) => {
        return item.shooter_categories
          ? //   ? item.shooter_categories.toLowerCase() === cate.toLowerCase()
          item.shooter_categories.toLowerCase().includes(cate.toLowerCase())
          : null;
      });
      console.log("filteredData: ", cate);
    }

    return filteredData;
  }

  //   function sortingArray(dataArray, colName) {
  //     return dataArray.sort(
  //       (a, b) => a[colName] - b[colName]
  //       // parseInt(a[colName]) > parseInt(b[colName]) ? 1 : -1
  //     );
  //   }

  function getMatchActive() {
    const instance = axios.create({
      baseURL: "https://api.idpasoth.com/stat/",
      headers: {
        Authorization: "Bearer 50d819e4d51dfc02e36bac6f27e9c3a9227f688f",
        method: "matchActived",
      },
    });
    instance
      .get()
      .then((res) => {
        console.log("match macthActive res: ", res);
        setMatchId(res.data);
      })
      .catch((err) => {
        console.log("axios macthActive err: ", err);
      });
  }

  function getMatchStat() {
    const instance = axios.create({
      baseURL: "https://api.idpasoth.com/stat/",
      headers: {
        Authorization: "Bearer 50d819e4d51dfc02e36bac6f27e9c3a9227f688f",
        method: "matchStat",
        mid: matchId,
      },
    });
    instance
      .get()
      .then((res) => {
        console.log("match stat res: ", res);
        // setCookie("matchStat", res.data); // set cookie
        setMatchStat(res.data);
        setDataLoaded(true);
        getOverall(res.data);
        setDataSet(filterData(res.data, div, cls, cate));
        // setDataSet(res.data);
        // if (div && div !== "") {
        //     setDataSet(
        //         dataSet.filter(
        //             (item) =>
        //                 item.shooter_division === div.toUpperCase()
        //         )
        //     );
        // }
        // if (cls && cls !== "") {
        //     setDataSet(
        //         dataSet.filter(
        //             (item) =>
        //                 item.shooter_class === cls.toUpperCase()
        //         )
        //     );
        // }
        // setMyStat(res.data); // set state
      })
      .catch((err) => {
        console.log("axios err: ", err);
        setDataLoaded(false);
      });
  }

  function getMatchInfo() {
    const instance = axios.create({
      baseURL: "https://api.idpasoth.com/stat/",
      headers: {
        Authorization: "Bearer 50d819e4d51dfc02e36bac6f27e9c3a9227f688f",
        method: "matchInfo",
        mid: matchId,
      },
    });
    instance
      .get()
      .then((res) => {
        console.log("match info res: ", res);
        setMatchInfo(res.data);
      })
      .catch((err) => {
        console.log("axios err: ", err);
      });
  }

  function getMatchCategory() {
    const instance = axios.create({
      baseURL: "https://api.idpasoth.com/stat/",
      headers: {
        Authorization: "Bearer 50d819e4d51dfc02e36bac6f27e9c3a9227f688f",
        method: "matchcategory",
        mid: matchId,
      },
    });
    instance
      .get()
      .then((res) => {
        // console.log("match info res: ", res);
        setMatchCategory(res.data);
      })
      .catch((err) => {
        console.log("axios cate err: ", err);
      });
  }

  function getOverall(items) {
    setOverall(
      items.filter((item) => {
        return (
          item.shooter_DNF === "0" && parseInt(item.shooter_total_score) > 0
        );
      })
    );
    console.log("Overall:", overall);
  }

  useEffect(() => {
    console.log("matchid: ", matchId);
    getMatchActive();
    if (matchId) {
      getMatchInfo();
      getMatchStat();
      getMatchCategory();
    }
    // console.log("match cate:", matchCategory);
    if (dataLoaded) {
      console.log(
        "filter:",
        matchStat.filter((item) => item.shooter_division.includes("PCC"))
      );
    }

    // cookie.overallPCC && console.log("Overall PCC:", cookie.overallPCC);
    // overallPistol && console.log("overall Pistol:", overallPistol);
    if (overall) {
    }

    AOS.init();
    // console.log("dataset: ", dataSet);
    // eslint-disable-next-line
  }, [matchId, dataLoaded]);

  useEffect(() => {
    setDataSet(filterData(overall, filterDiv, filterCls, filterCate));
  }, [overall, filterDiv, filterCls, filterCate]);

  const navigate = useNavigate();
  const handleSubmit = (event) => {
    event.preventDefault();
    // setFormDivision(event.target.value)
    // console.log("input:", event.target.division.value);
    // 👇️ redirect to /filter
    // const div = event.target.division.value ? event.target.division.value : "all";
    // const cls = event.target.class.value ? event.target.class.value : "all";
    // const cate = event.target.category.value ? event.target.category.value : "";
    setFilterDiv(
      event.target.division.value ? event.target.division.value : null
    );
    setFilterCls(event.target.class.value ? event.target.class.value : null);
    setFilterCate(
      event.target.category.value ? event.target.category.value : null
    );
    // setDataSet(filterData(overall));
    setDataSet(filterData(overall, filterDiv, filterCls, filterCate));
    // navigate("/filter/"+filterDiv, { replace: false });
  };

  function FilterNav() {
    return (
      <div className="bg-warning bg-gradient p-3 shadow-sm mb-3">
        <Form className="container" noValidate onSubmit={handleSubmit}>
          <Row className="gy-2 gx-3">
            <Form.Group as={Col} md="3">
              <Form.Control
                size="sm"
                type="text"
                name="division"
                id="division"
                placeholder={filterDiv ? filterDiv : "division"}
                defaultValue={filterDiv ? filterDiv : null}
              />
            </Form.Group>
            <Form.Group as={Col} md="3">
              <Form.Control
                size="sm"
                type="text"
                name="class"
                id="class"
                placeholder={filterCls ? filterCls : "classification"}
                defaultValue={filterCls ? filterCls : null}
              />
            </Form.Group>
            <Form.Group as={Col} md="4">
              <Form.Control
                size="sm"
                type="text"
                name="category"
                id="category"
                placeholder={filterCate ? filterCate : "category"}
                defaultValue={filterCate ? filterCate : null}
              />
            </Form.Group>
            <Form.Group as={Col} md="2">
              <Button size="sm" className="w-100" type="submit" style={{ letterSpacing: "0.1em" }}>
                Filter
              </Button>
            </Form.Group>
          </Row>
        </Form>
      </div>
    );
  }

  return (
    <div>
      {/* <PrizeNav /> */}
      <FilterNav />
      {/* <div>{main + " / " + sub}</div> */}
      {matchInfo ? (
        <div
          className="text-center fw-bold"
          style={{
            fontSize: "1.2em",
            letterSpacing: "2px",
            marginTop: "10px",
          }}
        >
          {matchInfo.match_name}
          <p style={{ fontWeight: "300", fontSize: "0.8em" }}>
            {matchInfo.match_begin}
            {` (${matchInfo.match_location})`}
          </p>
        </div>
      ) : null}
      <div className="container" style={{ marginBottom: "30px" }}>
        <TableResult
          rows={dataSet}
          div={filterDiv ? filterDiv.toUpperCase() : null}
          cls={filterCls ? filterCls.toUpperCase() : null}
          cate={filterCate ? filterCate.toUpperCase() : null}
          overallCount={overall ? Object.keys(overall).length : null}
        />
      </div>
      <div className="sticky-bottom">
        <Footer />
      </div>
    </div>
  );
}
