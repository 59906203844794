import React from 'react'
import ReactDOM from 'react-dom/client'
import { CookiesProvider } from 'react-cookie'
// import './index.css';
// import App from './App';
import reportWebVitals from './reportWebVitals'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import { ErrorPage404 } from './pages/Error'
import Signout from './pages/SignOut'
import Home from './pages/Home'
import Main from './pages/Main'
import Filter from './pages/Filter'
import PrizeCounter from './pages/PrizeCounter'

import './assets/css/BsStat.css'

const router = createBrowserRouter([
  {
    path: '/*',
    // loader: (sessionStorage.getItem("memberEmail")) => { return redirect('/home') },
    // element: {!memberCitizenId ? <Homepage /> : <Navigate to="/" />}
    // element: <Navigate to="/home" />
    element: <ErrorPage404 />,
  },
  {
    path: '/',
    // loader: (sessionStorage.getItem("memberEmail")) => { return redirect('/home') },
    // element: {!memberCitizenId ? <Homepage /> : <Navigate to="/" />}
    // element: <Navigate to="/home" />
    element: <Main />,
  },
  {
    path: '/result/:main',
    element: <Main />,
  },
  {
    path: '/result/:main/:sub',
    element: <Main />,
  },
  {
    path: '/filter',
    element: <Filter />,
  },
  {
    path: '/filter/:div',
    element: <Filter />,
  },
  {
    path: '/filter/:div/:cls',
    element: <Filter />,
  },
  {
    path: '/filter/:div/:cls/:cate',
    element: <Filter />,
  },
  {
    path: '/prizecounter',
    element: <PrizeCounter />,
  },
  {
    path: '/home',
    // loader: (sessionStorage.getItem("memberEmail")) => { return redirect('/home') },
    // element: {!memberCitizenId ? <Homepage /> : <Navigate to="/" />}
    // element: <Navigate to="/home" />
    element: <Home />,
  },
  {
    path: '/signout',
    element: <Signout />,
  },
])

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  // <React.StrictMode>
  <CookiesProvider defaultSetOptions={{ path: '/' }}>
    <RouterProvider router={router} />{' '}
  </CookiesProvider>,
  // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
