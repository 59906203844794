import { useRouteError } from "react-router-dom";

export function ErrorPage404() {
  const error = useRouteError();
  console.log(error);
  sessionStorage.clear();
  return (
    <div style={{ marginTop: "100px", textAlign: "center" }}>
      <h4>ERROR</h4>
      <h2>404 Page Not Found</h2>
      <div style={{ marginTop: "50px" }}>
        <a
          href="/"
          target="_top"
          style={{
            fontSize: "20px",
            textDecoration: "none",
          }}
        >
          Homepage
        </a>
      </div>
    </div>
  );
}
