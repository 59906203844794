import { useEffect, useState } from "react";
// import { Link, useNavigate, useParams } from "react-router-dom";
import { useParams } from "react-router-dom";
// import { useCookies } from "react-cookie";
import AOS from "aos";
import axios from "axios";

import { StatNavbar } from "../components/Main_element";
import {
  StatOverAll,
  DivisionChampionAll,
  DivisionChampion,
  DivisionClassAll,
  DivisionClass,
  DivisionCategoryAll,
  DivisionCategory,
  StageLeader,
} from "../components/Stat_element";
import { Footer } from "../components/Home_element";

export default function Main(props) {
  //   const mid = props.mid;
  const [matchId, setMatchId] = useState('');
  const { main, sub } = useParams();
  const [dataLoaded, setDataLoaded] = useState(false);
  //   const [overallPCC, setOverallPCC] = useState(false);
  const [matchInfo, setMatchInfo] = useState();
  const [matchStat, setMatchStat] = useState([]);
  const [matchCategory, setMatchCategory] = useState([]);
  const [overall, setOverall] = useState([]);
  const [overallPistol, setOverallPistol] = useState([]);
  const [overallPCC, setOverallPCC] = useState([]);
  const [mostAll, setMostAll] = useState([]);
  const [mostPistol, setMostPistol] = useState([]);
  const [mostPCC, setMostPCC] = useState([]);
  const [dcAll, setDcAll] = useState([]);
  // const [dcSSP, setDcSSP] = useState([]);
  const [dnf, setDnf] = useState([]);
  const [categoryResult, setCategoryResult] = useState([]);
  const [stageLeader, setStageLeader] = useState([]);

  const classes = ["DM", "MA", "EX", "SS", "MM", "NV", "UN"];
  const divisions = [
    "SSP",
    "ESP",
    "CCP",
    "CDP",
    "BUG",
    "REV",
    "CO",
    "PCC",
    "SPD",
  ];
  // const categories = [
  //   "100+",
  //   "Distinguished Senior",
  //   "Government & State Enterprises",
  //   "Internationl",
  //   "Junior",
  //   "Lady",
  //   "Law Enforcement",
  //   "Military",
  //   "Senior",
  //   "State Enterprises",
  // ];

  function sortingArray(dataArray, colName) {
    return dataArray.sort(
      (a, b) => a[colName] - b[colName]
      // parseInt(a[colName]) > parseInt(b[colName]) ? 1 : -1
    );
  }

  function getMatchActive() {
    const instance = axios.create({
      baseURL: "https://api.idpasoth.com/stat/",
      headers: {
        Authorization: "Bearer 50d819e4d51dfc02e36bac6f27e9c3a9227f688f",
        method: "matchActived",
      },
    });
    instance
      .get()
      .then((res) => {
        console.log("match macthActive res: ", res);
        setMatchId(res.data);
      })
      .catch((err) => {
        console.log("axios macthActive err: ", err);
      });
  }

  function getMatchStat() {
    const instance = axios.create({
      baseURL: "https://api.idpasoth.com/stat/",
      headers: {
        Authorization: "Bearer 50d819e4d51dfc02e36bac6f27e9c3a9227f688f",
        method: "matchStat",
        mid: matchId,
      },
    });
    instance
      .get()
      .then((res) => {
        console.log("Match Stat res: ", res);
        // setCookie("matchStat", res.data); // set cookie
        setMatchStat(res.data);
        setDataLoaded(true);
        getOverall(res.data);
        // setMyStat(res.data); // set state
      })
      .catch((err) => {
        console.log("axios stat err: ", err);
        setDataLoaded(false);
      });
  }

  function getMatchInfo() {
    const instance = axios.create({
      baseURL: "https://api.idpasoth.com/stat/",
      headers: {
        Authorization: "Bearer 50d819e4d51dfc02e36bac6f27e9c3a9227f688f",
        method: "matchInfo",
        mid: matchId,
      },
    });
    instance
      .get()
      .then((res) => {
        // console.log("match info res: ", res);
        setMatchInfo(res.data);
      })
      .catch((err) => {
        console.log("axios info err: ", err);
      });
  }

  function getMatchCategory() {
    const instance = axios.create({
      baseURL: "https://api.idpasoth.com/stat/",
      headers: {
        Authorization: "Bearer 50d819e4d51dfc02e36bac6f27e9c3a9227f688f",
        method: "matchcategory",
        mid: matchId,
      },
    });
    instance
      .get()
      .then((res) => {
        // console.log("match info res: ", res);
        setMatchCategory(res.data);
      })
      .catch((err) => {
        console.log("axios cate err: ", err);
      });
  }

  function getStageLeader(mid, stage) {
    const instance = axios.create({
      baseURL: "https://api.idpasoth.com/stat/",
      headers: {
        Authorization: "Bearer 50d819e4d51dfc02e36bac6f27e9c3a9227f688f",
        method: "stageLeader",
        mid: mid,
        stage: stage,
      },
    });
    instance
      .get()
      .then((res) => {
        console.log("stage leader res: ", res);
        setStageLeader(res.data);
      })
      .catch((err) => {
        console.log("axios stage err: ", err);
      });
  }

  function filterData(dataset, div, cls, cate) {
    let filteredData = dataset.filter((item) => {
      return item.shooter_DNF === "0" && parseInt(item.shooter_total_score) > 0;
    });

    if (div && div !== "all") {
      filteredData = filteredData.filter((item) => {
        return item.shooter_division === div.toUpperCase();
      });
      console.log("filteredData: ", div);
    }
    if (cls && cls !== "all") {
      filteredData = filteredData.filter((item) => {
        return item.shooter_class === cls.toUpperCase();
      });
      console.log("filteredData: ", cls);
    }
    if (cate && cate !== "") {
      if (cate === "not null") {
        filteredData = filteredData.filter((item) => {
          return item.shooter_categories
            ? //   ? item.shooter_categories.toLowerCase() === cate.toLowerCase()
            item.shooter_categories.toLowerCase() !== ""
            : null;
        });
      } else {
        filteredData = filteredData.filter((item) => {
          return item.shooter_categories
            ? //   ? item.shooter_categories.toLowerCase() === cate.toLowerCase()
            item.shooter_categories.toLowerCase().includes(cate.toLowerCase())
            : null;
        });
      }
      console.log("filteredData: ", cate);
    }

    return filteredData;
  }

  function getOverall(items) {
    setOverall(
      items.filter((item) => {
        return item.shooter_DNF === "0" && item.shooter_total_score > 0;
      })
    );
    // console.log("Overall:", overall);

    setOverallPCC(
      items.filter((item) => {
        return (
          item.shooter_DNF === "0" &&
          item.shooter_total_score > 0 &&
          item.shooter_division.includes("PCC")
        );
      })
    );
    // console.log("Overall PCC:", overallPCC);

    setOverallPistol(
      items.filter((item) => {
        return (
          //   item.shooter_DNF === "null" && item.shooter_division.includes("PCC")
          item.shooter_DNF === "0" &&
          item.shooter_total_score > 0 &&
          (item.shooter_division === "SSP" ||
            item.shooter_division === "ESP" ||
            item.shooter_division === "CCP" ||
            item.shooter_division === "CDP" ||
            item.shooter_division === "BUG" ||
            item.shooter_division === "REV" ||
            item.shooter_division === "SPD" ||
            item.shooter_division === "CO")
        );
      })
    );
    // console.log("Overall Pistol:", overallPistol);

    setMostAll(
      overall.filter((item) => {
        return (
          parseInt(item.shooter_total_score) > 0 && !parseInt(item.shooter_HNT)
        );
      })
    );
    // console.log("Most Pistol:", mostPistol);

    setMostPistol(
      overallPistol.filter((item) => {
        return (
          parseInt(item.shooter_total_score) > 0 && !parseInt(item.shooter_HNT)
        );
      })
    );
    // console.log("Most Pistol:", mostPistol);

    setMostPCC(
      overallPCC.filter((item) => {
        return (
          item.shooter_DNF === "0" &&
          parseInt(item.shooter_total_score) > 0 &&
          !parseInt(item.shooter_HNT)
        );
      })
    );
    // console.log("Most PCC:", mostPCC);

    // setMostPCC(sortingArray(mostPCC, "shooter_PD"));
    // console.log("setDcAll:", mostPCC);

    function genDivisionChampion_bk(div) {
      let dc = [];
      if (div === "SPD") {
        dc["ALL"] = items.filter((item) => {
          return (
            item.shooter_DNF === "0" &&
            parseInt(item.shooter_total_score) > 0 &&
            item.shooter_division === "SPD"
          );
        });
      } else {
        classes.map((cls) => {
          dc[cls] = items.filter((item) => {
            return (
              item.shooter_DNF === "0" &&
              parseInt(item.shooter_total_score) > 0 &&
              item.shooter_division === div &&
              item.shooter_class === cls
            );
          });
        });
      }
      console.log(`DC ${div}:`, dc);
      return dc;
    }

    function genDivisionChampion(div) {
      // console.error("ไอเทม: ", items);
      let dc = [];
      if (div === "SPD") {
        dc["ALL"] = items.filter((item) => {
          return (
            item.shooter_DNF === "0" &&
            parseInt(item.shooter_total_score) > 0 &&
            item.shooter_division === "SPD"
          );
        });
      } else {
        // classes.map((cls) => {
        dc["ALL"] = items.filter((item) => {
          return (
            item.shooter_DNF === "0" &&
            parseInt(item.shooter_total_score) > 0 &&
            item.shooter_division === div
          );
        });
        // });
      }
      //   console.log(`DC ${div}:`, dc);
      return dc;
    }

    let dcAllTemp = [];
    divisions.map((div) => {
      dcAllTemp[div] = overall.filter((items) =>
        items.shooter_division.includes(div)
      );
      //   dcAllTemp[div] = genDivisionChampion(div);
    });
    setDcAll(dcAllTemp);
    if (dcAll.lenght) {
      console.log(`DC:`, dcAll);
    }

    setDnf(
      items.filter((item) => {
        return (
          //   item.shooter_DNF === "null" && item.shooter_division.includes("PCC")
          item.shooter_DNF === "1" || item.shooter_total_score <= 0
        );
      })
    );
    // console.log("DNF Pistol:", dnf);

    function genShooterCategory(div) {
      let dc = [];
      if (div === "SPD") {
        dc["ALL"] = items.filter((item) => {
          return (
            item.shooter_DNF === "0" &&
            parseInt(item.shooter_total_score) > 0 &&
            item.shooter_division === "SPD"
          );
        });
      } else {
        classes.map((cls) => {
          dc[cls] = items.filter((item) => {
            return (
              item.shooter_DNF === "0" &&
              parseInt(item.shooter_total_score) > 0 &&
              item.shooter_division === div &&
              item.shooter_class === cls
            );
          });
        });
      }
      //   console.log(`DC ${div}:`, dc);
      return dc;
    }

    let shooterCategoryTemp = [];
    matchCategory.map((cate) => {
      shooterCategoryTemp[cate] = genShooterCategory(cate);
    });
    setCategoryResult(shooterCategoryTemp);
    // console.log(`Cate res:`, categoryResult);
    if (categoryResult.lenght) {
      console.log(`Cate res:`, categoryResult);
    }
  }

  function LoadingStatus() {
    return dataLoaded ? (
      <div className="text-center my-5 text-danger">
        <h3>Data Loaded.</h3>
      </div>
    ) : (
      <div className="text-center my-5 text-danger">
        <h3>Loading...</h3>
      </div>
    );
  }

  useEffect(() => {
    getMatchActive();
    if (matchId) {
      console.log("matchid: ", matchId);
      getMatchInfo();
      getMatchStat();
      getMatchCategory();
    }
    // console.log("match cate:", matchCategory);
    if (matchCategory.lenght) {
      console.log(`Match Cate:`, matchCategory);
    }
    if (dataLoaded) {
      console.log(
        "Sample Filter:",
        matchStat.filter((item) => item.shooter_division.includes("PCC"))
      );
    }

    // cookie.overallPCC && console.log("Overall PCC:", cookie.overallPCC);
    // overallPistol && console.log("overall Pistol:", overallPistol);

    AOS.init();
    // eslint-disable-next-line
  }, [matchId, dataLoaded]);

  useEffect(() => {
    if (main && main === "stage" && sub && sub > 0) {
      getStageLeader(matchId, sub);
    }
  }, [matchId, main, sub]);

  return (
    <>
      <StatNavbar
        matchCategories={matchCategory}
        matchStageNumber={matchInfo ? matchInfo.match_stages : 10}
      />
      {/* <div>{main + " / " + sub}</div> */}
      {matchInfo ? (
        <div
          className="text-center fw-bold"
          style={{
            fontSize: "1.2em",
            letterSpacing: "2px",
            marginTop: "10px",
          }}
        >
          {matchInfo.match_name}
        </div>
      ) : null}
      {/* <div className="text-center my-5 text-danger">{(main === "" && dataLoaded) ? <h3>Data Loaded.</h3> : <h3>Loading...</h3>}</div> */}
      <div className="container-md" style={{ marginBottom: "30px" }}>
        {!main || main === "" ? <LoadingStatus /> : null}
        {main === "overall" && !sub ? (
          <StatOverAll
            title={`Over All (${overall.length}/${matchStat.length})`}
            items={overall}
          />
        ) : null}
        {main === "overall" && sub === "pcc" ? (
          <StatOverAll
            title={`Over All : PCC (${overallPCC.length}/${matchStat.length})`}
            // items={overallPCC.slice(0, 10)}
            items={overallPCC}
          />
        ) : null}
        {main === "overall" && sub === "pistol" ? (
          <StatOverAll
            title={`Over All : Pistol (${overallPistol.length}/${matchStat.length})`}
            // items={overallPistol.slice(0, 10)}
            items={overallPistol}
          />
        ) : null}
        {main === "most" && !sub ? (
          <StatOverAll
            title={`Most Accuracy : All (${mostAll.length}/${matchStat.length})`}
            items={sortingArray(mostAll, "shooter_PD").slice(0, 5)}
          />
        ) : null}
        {main === "most" && sub === "pistol" ? (
          <StatOverAll
            title={`Most Accuracy : Pistol (${mostPistol.length}/${matchStat.length})`}
            items={sortingArray(mostPistol, "shooter_PD").slice(0, 5)}
          />
        ) : null}
        {main === "most" && sub === "pcc" ? (
          <StatOverAll
            title={`Most Accuracy : PCC (${mostPCC.length}/${matchStat.length})`}
            // items={mostPCC.slice(0, 10)}
            items={sortingArray(mostPCC, "shooter_PD").slice(0, 5)}
          />
        ) : null}
        {/* {dcAll ? JSON.stringify(dcAll) : null} */}
        {main === "dc" && !sub && dcAll ? (
          <DivisionChampionAll items={dcAll} />
        ) : null}
        {main === "dc" && sub && dcAll ? (
          <DivisionChampion
            matchShooterCount={matchStat.length}
            div={sub.toUpperCase()}
            items={dcAll[sub.toUpperCase()]}
          />
        ) : null}
        {main === "class" && !sub && overall ? (
          <DivisionClassAll
            items={overall}
            matchShooterCount={overall.length}
          />
        ) : null}
        {main === "class" && sub && dcAll ? (
          <DivisionClass
            matchShooterCount={matchStat.length}
            div={sub.toUpperCase()}
            items={overall.filter((dcAll) =>
              dcAll.shooter_division.includes(sub.toUpperCase())
            )}
          />
        ) : null}
        {main === "cate" && !sub && overall ? (
          <DivisionCategoryAll
            items={filterData(overall, "", "", "not null")}
            matchShooterCount={matchStat.length}
            matchCategories={matchCategory}
          />
        ) : null}
        {main === "cate" && sub && overall ? (
          <DivisionCategory
            matchShooterCount={matchStat.length}
            cate={sub.toUpperCase()}
            items={filterData(overall, "", "", sub)}
          />
        ) : null}
        {main === "stage" && sub && overall ? (
          <StageLeader
            // matchShooterCount={overall.length}
            stage={sub}
            items={stageLeader}
          />
        ) : null}
        {main === "dnf" ? (
          <StatOverAll
            title={`DNF (${dnf.length}/${matchStat.length})`}
            items={dnf}
          />
        ) : null}
      </div>
      <Footer />
    </>
  );
}
