import "bootstrap-icons/font/bootstrap-icons.css";
import { useState } from "react";
import Table from "react-bootstrap/Table";

export const TableResult = ({ rows, div, cls, cate, overallCount }) => {
  // const [sortedRows, setRows] = useState(rows)

//   console.log("sortedRows", rows);

  return (
    <>
      {/* <p>{JSON.stringify(itemsDivChampion)}</p> */}
      <div key={`TblDivChampion_${div}/${cls ? cls : null}`}>
        <h5 style={{ marginTop: "40px", fontSize:"1.2em" }}>
          {`Filtered Div: ${div ? div : "All"} / Cls: ${
            cls ? cls : "All"
          } / Category: ${cate ? cate : "All"}`}
        </h5>
        <p style={{ fontWeight: "300", fontSize: "0.9em" }}>{`[ Shooter: ${
          rows ? Object.keys(rows).length : "0"
        } / ${overallCount} ]`}</p>
        <Table
          responsive="md"
          striped
          bordered
          hover
          style={{
            fontSize: "0.75em",
            width: "100%",
            marginBottom: "20px",
          }}
          className="TblResult shadow-sm"
        >
          <thead style={{position: "sticky", top: "-1px"}} className="table-primary "> 
            <tr style={{height:"50px", verticalAlign:"middle"}}>
              <th
                className="text-center text-nowrap px-2"
                style={{ width: "50px" }}
              >
                Place
              </th>
              <th className="text-start px-2">Shooter</th>
              <th
                className="text-center text-nowrap px-2 d-none d-md-table-cell"
                style={{ width: "90px" }}
              >
                Div/Class
              </th>
              <th
                className="text-center text-nowrap px-2 d-none d-lg-table-cell"
                style={{ width: "80px" }}
              >
                No / SQ
              </th>
              <th
                className="text-center text-nowrap px-2 d-none d-lg-table-cell"
                style={{ width: "100px" }}
              >
                Category
              </th>
              {/* <th className="text-center">Categories</th> */}
              <th
                className="text-center text-nowrap px-2"
                style={{ width: "80px" }}
              >
                Time
              </th>
              <th
                className="text-center text-nowrap px-2"
                style={{ width: "60px" }}
              >
                PD
              </th>
              <th
                className="text-center text-nowrap px-2"
                style={{ width: "60px" }}
              >
                HNT
              </th>
            </tr>
          </thead>
          <tbody>
            {rows.map((item, key) => (
              <tr key={key}>
                <td className="text-center">{key + 1}</td>
                <td className="">
                  {item["shooter_idpa"] ? (
                    <div className="idpaNumber">{item["shooter_idpa"]}</div>
                  ) : null}
                  {`${item["shooter_firstName"]}  ${item["shooter_lastName"]}`}
                </td>
                <td className="text-center text-nowrap d-none d-md-table-cell">
                  {item["shooter_division"] + " / " + item["shooter_class"]}
                </td>
                <td className="text-center text-nowrap d-none d-lg-table-cell">
                  {item["shooter_number"] + " / " + item["shooter_squad"]}
                </td>
                <td className="text-center d-none d-lg-table-cell">
                  {item["shooter_categories"]}
                </td>
                {/* <td className="text-center">{item["shooter_categories"]}</td> */}
                <td className="text-center">{item["shooter_total_score"]}</td>
                <td className="text-center">{item["shooter_PD"]}</td>
                <td className="text-center">{item["shooter_HNT"]}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );

  // return (
  //     <table>
  //         <thead>
  //             <tr>
  //                 {/* {Object.keys(rows[0]).map((entry, index) => (
  //           <th key={index}>{entry}</th>
  //         ))} */}
  //                 <th>a</th>
  //                 <th>b</th>
  //                 <th>C</th>
  //                 <th>div/class</th>
  //                 <th>Score</th>
  //                 <th>cate</th>
  //             </tr>
  //         </thead>
  //         <tbody>
  //             {rows.map((row, index) => (
  //                 <tr key={index}>
  //                     {/* {Object.values(row).map((entry, columnIndex) => ( */}
  //                     <td>{row.shooter_firstName}</td>
  //                     <td>{row.shooter_lastName}</td>
  //                     <td>{row.shooter_number}</td>
  //                     <td>{`${row.shooter_division} / ${row.shooter_class}`}</td>
  //                     <td>{row.shooter_total_score}</td>
  //                     <td>{row.shooter_categories}</td>
  //                     {/* ))} */}
  //                 </tr>
  //             ))}
  //         </tbody>
  //     </table>
  // );
};
